import { ICall, ICallResp } from '../interfaces/call-history';

export class CallHistory implements ICall {
    billingTime: string;
    callId: string;
    completed: boolean;
    createdOn: Date;
    direction: string;
    dialedDigits: string;
    endTime: Date;
    from: string;
    fromName: string;
    fromNumberE164: boolean;
    fromPrincipalId: string;
    to: string;
    toName: string;
    toPrincipalId: string;
    initials: string;
    avatars: string;
    isMissedCall: boolean;
    isInboundCall: boolean;
    isActionInProgress: boolean; // Delete, mark favourite

    constructor(param: ICallResp) {
        this.callId = param.callId;
        this.completed = param.completed;
        this.createdOn = new Date(param.createdOn);
        this.dialedDigits = param.dialedDigits;
        this.direction = param.direction;
        this.endTime = new Date(param.endTime);
        this.from =  param.from;
        this.fromName = param.fromName;
        this.fromNumberE164 = param.fromNumberE164;
        this.fromPrincipalId = param.fromPrincipalId;
        this.to = param.to;
        this.toName = param.toName;
        this.toPrincipalId = param.toPrincipalId;
        this.isMissedCall = param?.direction === 'inbound' && !param?.completed || false;
        this.isInboundCall = param?.direction === 'inbound' && param?.completed || false;
    }

}

export class CallHistoryResp implements ICallResp {
    billingTime: string;
    callId: string;
    completed: boolean;
    createdOn: string;
    dialedDigits: string;
    direction: string;
    endTime: string;
    from: string;
    fromName: string;
    fromNumberE164: boolean;
    fromPrincipalId: string;
    to: string;
    toName: string;
    toNumberE164: boolean;
    toPrincipalId: string;
    constructor(param: any) {
        this.billingTime = param.billingTime;
        this.callId = param.callId;
        this.completed = param.completed;
        this.createdOn = param.createdOn;
        this.dialedDigits = param.dialedDigits;
        this.direction = param.direction;
        this.endTime = param.endTime;
        this.from = param.from;
        this.fromName = param.fromName;
        this.fromNumberE164 = param.fromNumberE164;
        this.fromPrincipalId = param.fromPrincipalId;
        this.to = param.to;
        this.toName = param.toName;
        this.toNumberE164 = param.toNumberE164;
        this.toPrincipalId = param.toPrincipalId;
    }
}
